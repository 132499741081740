import {useQuery} from 'graphql/client';
import {
    NotificationMessage,
    NOTIFICATION_MESSAGES,
} from 'core/Notification/types';
import {NotificationsContext} from 'components/contexts/notifications';
import NOTIFICATION_MESSAGES_QUERY from 'graphql/components/ui/notification/notificationMessages.graphql';

interface NotificationsProviderProps {
    children: React.ReactNode;
}

function NotificationsProvider({children}: NotificationsProviderProps) {
    const {data} = useQuery(NOTIFICATION_MESSAGES_QUERY, {});
    let notificationMessages = [];

    if (data) {
        notificationMessages = data[
            NOTIFICATION_MESSAGES
        ] as NotificationMessage[];
    }

    return (
        <NotificationsContext.Provider value={notificationMessages}>
            {children}
        </NotificationsContext.Provider>
    );
}

export {NotificationsProvider};
