export const NOTIFICATION_MESSAGES = 'notificationMessages';

export enum NotificationMessageType {
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export type NotificationMessageOptions = {
    removeAfter?: number;
    preserveOnPageChange?: boolean;
    processing?: boolean;
    title?: string;
    id?: string;
};

export enum NotificationMessageStatus {
    DISPLAYED = 'displayed',
    REMOVED = 'removed',
}

export type NotificationMessage = {
    content: string;
    id: string;
    options?: NotificationMessageOptions;
    status: NotificationMessageStatus;
    type: NotificationMessageType;
};

export type NotificationInput = {
    message: string;
    type: NotificationMessageType;
    options: NotificationMessageOptions;
};
