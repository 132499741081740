import {localStorageUtil} from 'utils/localStorageUtil';
import NOTIFICATION_MESSAGES_QUERY from 'graphql/components/ui/notification/notificationMessages.graphql';
import {
    NotificationMessage,
    NotificationMessageStatus,
    NOTIFICATION_MESSAGES,
} from 'core/Notification/types';
import {ApolloClient} from '@apollo/client';

type Cache = {
    [NOTIFICATION_MESSAGES]?: NotificationMessage[];
};

export default function initCache(client: ApolloClient<Cache>) {
    const notificationMessages = (localStorageUtil.get(NOTIFICATION_MESSAGES) ||
        []) as NotificationMessage[];

    client.writeQuery({
        query: NOTIFICATION_MESSAGES_QUERY,
        data: {
            [NOTIFICATION_MESSAGES]: notificationMessages.filter(
                (message) =>
                    message.status !== NotificationMessageStatus.REMOVED
            ),
        },
    });
}
