import {ApolloClient, InMemoryCache, HttpLink, from} from '@apollo/client';
import 'cross-fetch/polyfill';
import {dateTimeLink} from './datetime';

const httpLink = new HttpLink({uri: '/api/graphql'});

const client = new ApolloClient({
    link: from([dateTimeLink, httpLink]),
    cache: new InMemoryCache({}),
});

export default client;
