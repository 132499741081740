import React, {ElementType, JSXElementConstructor, forwardRef} from 'react';
import Typography from '@mui/material/Typography';

interface CustomTypographyProps {
    children: React.ReactNode;
    component?: ElementType;
    [key: string]: unknown;
}

const CustomTypography = forwardRef<HTMLElement, CustomTypographyProps>(
    function CustomTypography({children, component, ...props}, ref) {
        assertValidDomNesting(
            children,
            component,
            props as CustomTypographyProps
        );

        return (
            <Typography ref={ref} component={component} {...props}>
                {children}
            </Typography>
        );
    }
);

function throwInvalidDOMNestingError(
    children: React.ReactNode,
    props?: Partial<CustomTypographyProps>
) {
    throw new Error(
        `Typography received children that are not strings or an array of strings. Received: ${
            printReactChildren(children) || 'undefined'
        } with props ${JSON.stringify(
            props
        )} Please add a component prop (usually "div") to ensure valid DOMnesting.`
    );
}

function printReactChildren(children: React.ReactNode) {
    return JSON.stringify(children);
}

function isTypeInvalid(type: string | JSXElementConstructor<string>) {
    const invalidTypes = [
        'div',
        'p',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ol',
        'ul',
        'li',
        'blockquote',
        'pre',
        'header',
        'footer',
        'section',
        'article',
        'aside',
        'nav',
        'figure',
        'figcaption',
        'table',
        'form',
        'fieldset',
        'hr',
        'button',
        'audio',
        'video',
        'embed',
        'iframe',
        'object',
        'script',
        'noscript',
    ];

    return invalidTypes.includes(type as string);
}

function isOneOfChildrenAnInvalidElement(children: React.ReactNode): boolean {
    if (Array.isArray(children)) {
        return children.some(
            (child) => React.isValidElement(child) && isTypeInvalid(child.type)
        );
    }

    return React.isValidElement(children) && isTypeInvalid(children.type);
}

function doChildrenHaveInvalidDomNesting(
    children: React.ReactNode,
    component?: ElementType
) {
    return (
        typeof children !== 'string' &&
        (component === 'p' || component === undefined) &&
        isOneOfChildrenAnInvalidElement(children)
    );
}

function assertValidDomNesting(
    children: React.ReactNode,
    component?: ElementType,
    props?: Partial<CustomTypographyProps>
) {
    if (Array.isArray(children)) {
        children.forEach((child) => {
            if (doChildrenHaveInvalidDomNesting(child, component)) {
                throwInvalidDOMNestingError(children, props);
            }
        });
    } else if (doChildrenHaveInvalidDomNesting(children, component)) {
        throwInvalidDOMNestingError(children, props);
    }
}

export default CustomTypography;
