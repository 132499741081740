import {
    createLastKnownRouteStore,
    LastKnownStore,
} from 'components/stores/lastRouteStore';
import {useRouter} from 'next/router';

import {
    type ReactNode,
    createContext,
    useRef,
    useContext,
    useEffect,
} from 'react';
import {useStore} from 'zustand';

export const ROUTES_TO_IGNORE_FOR_LAST_KNOWN_ROUTE_PROVIDER = [
    '/login',
    'login',
    'logout',
    '/logout',
];

export type LastKnownStoreApi = ReturnType<typeof createLastKnownRouteStore>;

export const LastKnownRouteStoreContext = createContext<
    LastKnownStoreApi | undefined
>(undefined);

export interface LastKnownStoreProviderProps {
    children: ReactNode;
}

export const LastKnownRouteProvider = ({
    children,
}: LastKnownStoreProviderProps) => {
    const storeRef = useRef<LastKnownStoreApi>();
    const router = useRouter();

    if (!storeRef.current) {
        storeRef.current = createLastKnownRouteStore();
    }

    const currentPath = router?.asPath;
    const pathname = router?.pathname;

    useEffect(() => {
        const hasRouteChanged =
            currentPath !== storeRef.current.getState().route;

        const areRoutesValid =
            !ROUTES_TO_IGNORE_FOR_LAST_KNOWN_ROUTE_PROVIDER.some((route) =>
                currentPath?.includes(route)
            ) &&
            !pathname?.includes('404') &&
            !pathname?.includes('500');

        if (storeRef.current && areRoutesValid && hasRouteChanged) {
            storeRef.current.setState((state) => ({
                ...state,
                route: currentPath,
            }));
        }
    }, [currentPath, pathname]);

    return (
        <LastKnownRouteStoreContext.Provider value={storeRef.current}>
            {children}
        </LastKnownRouteStoreContext.Provider>
    );
};

export const useLastKnownRouteStore = <T,>(
    selector: (store: LastKnownStore) => T
): T => {
    const lastKnownStoreContext = useContext(LastKnownRouteStoreContext);

    if (!lastKnownStoreContext) {
        throw new Error(
            `useLastKnownRouteStore must be used within LastKnownRouteProvider`
        );
    }

    return useStore(lastKnownStoreContext, selector);
};
