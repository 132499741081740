import {useEffect} from 'react';
import type User from 'core/User';
import Hotjar from '@hotjar/browser';
import mixpanel from 'mixpanel-browser';

export const isMixpanelConfigured = () => {
    return Boolean(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN);
};

const identifyUser = (user: User) => {
    Hotjar.identify(user.id, {
        name: user.name,
        company_name: user.clientOrganization.name,
        is_super_user: user.isSuperuser,
    });
    if (isMixpanelConfigured()) {
        mixpanel.identify(user.id);
        mixpanel.people.set({
            $name: user.name,
            $email: user.emailAddress,
            is_superuser: user.isSuperuser,
            client_organization_name: user.clientOrganization.name,
            client_organization_id: user.clientOrganization.id,
        });
    }
};

const trackEvent = (eventName: string) => {
    Hotjar.event(eventName);
};

const useTracking = () => {
    useEffect(() => {
        Hotjar.init(
            Number(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID),
            Number(process.env.NEXT_PUBLIC_HOTJAR_VERSION)
        );
        if (isMixpanelConfigured()) {
            mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN, {
                debug: process.env.NODE_ENV === 'development',
                track_pageview: 'url-with-path',
            });
        }
    }, []);
};

const trackEventWithMixPanel = (
    eventName: string,
    context: Record<string, unknown>
) => {
    if (isMixpanelConfigured()) {
        mixpanel.track(eventName, context);
    }
};

export default useTracking;
export {identifyUser, trackEvent, trackEventWithMixPanel};
