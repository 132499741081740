let baseLogger;

if (typeof window === 'undefined') {
    const winston = require('winston');
    baseLogger = winston.createLogger({
        transports: [
            new winston.transports.File({
                filename: '/tmp/web-sdp.log',
                maxsize: 500000,
                maxFiles: 1,
            }),
            new winston.transports.Console({
                level: 'debug',
            }),
        ],
    });
} else {
    baseLogger = console;
}
class Logger {
    constructor(source) {
        this.source = source;
    }
    info(message, ...args) {
        baseLogger.info(`[${this.source}] ${message}`, ...args);
    }
    warn(message, ...args) {
        baseLogger.warn(`[${this.source}] ${message}`, ...args);
    }
    error(message, ...args) {
        baseLogger.error(`[${this.source}] ${message}`, ...args);
    }
}

const logger = new Logger('default');

export {Logger, logger};
