const ADD_CUSTOM_LENS_URL = '/signals/add';
const ADD_NOTIFICATION_RULE_URL = '/notification-rules/add';
const AICPS_URL = '/aicps';
const AICP_URL = '/aicp';
const CONNECTORS_URL = '/connectors';
const EXPORT_HISTORY_URL = '/export-history';
const SALESFORCE_MAPPINGS_LIST_URL = CONNECTORS_URL + '/salesforce/profiles';
const SALESFORCE_SANDBOX_MAPPINGS_LIST_URL =
    CONNECTORS_URL + '/salesforce-sandbox/profiles';
const ADD_SALESFORCE_MAPPING_URL = SALESFORCE_MAPPINGS_LIST_URL + '/add';
const SALESFORCE_SETTINGS = CONNECTORS_URL + '/salesforce/settings';
const ADD_SALESFORCE_SANDBOX_MAPPING_URL =
    SALESFORCE_SANDBOX_MAPPINGS_LIST_URL + '/salesforce-sandbox/profiles/add';
const CREATE_ICP_URL = '/create-aicp';
const SAVED_SIGNALS_URL = '/signals';
const EDIT_USER_URL = '/settings/edit-user';
const HOMEPAGE_URL = '/';
const SIGNAL_CHAT_URL = '/signals/chat';
const EXPLORE_SIGNALS_URL = '/signals/explore';
const SIGNAL_NEW_URL = '/signals/new';
const LOGIN_URL = '/login';
const PRIVACY_POLICY_URL = 'https://getrev.ai/privacy-policy/';
const COMPANY_RESEARCH_URL = '/company-research';
const SETTINGS_URL = '/settings';
const TERMS_AND_CONDITIONS_URL = 'https://getrev.ai/terms-conditions/';
const COMPANY_LOOKUP_URL = '/company-lookup';

const QUERY_PARAM_TAB_APP_SETTINGS = 'app-settings';
const QUERY_PARAM_TAB_USERS_AND_PERMISSIONS = 'users-and-permissions';

const SUPERUSER_CLIENT_ORGANIZATIONS_URL = '/superuser/client-organizations';

const SUPERUSER = Object.freeze({
    CLIENT_ORGANIZATIONS_URL: SUPERUSER_CLIENT_ORGANIZATIONS_URL,
    CREATE_CLIENT_ORGANIZATION: SUPERUSER_CLIENT_ORGANIZATIONS_URL + '/add',
    EDIT_USER_URL: '/superuser/user',
    USERS_URL: '/superuser/users',
});

const buildExportHistoryFailuresUrl = (entityExportId: string) =>
    `${EXPORT_HISTORY_URL}/${entityExportId}/failures`;

export {
    ADD_CUSTOM_LENS_URL,
    ADD_NOTIFICATION_RULE_URL,
    AICP_URL,
    AICPS_URL,
    COMPANY_RESEARCH_URL,
    CONNECTORS_URL,
    EXPORT_HISTORY_URL,
    buildExportHistoryFailuresUrl,
    SALESFORCE_MAPPINGS_LIST_URL,
    SALESFORCE_SETTINGS,
    ADD_SALESFORCE_MAPPING_URL,
    ADD_SALESFORCE_SANDBOX_MAPPING_URL,
    SALESFORCE_SANDBOX_MAPPINGS_LIST_URL,
    CREATE_ICP_URL,
    SAVED_SIGNALS_URL,
    EDIT_USER_URL,
    HOMEPAGE_URL,
    SIGNAL_CHAT_URL,
    EXPLORE_SIGNALS_URL,
    SIGNAL_NEW_URL,
    LOGIN_URL,
    PRIVACY_POLICY_URL,
    QUERY_PARAM_TAB_APP_SETTINGS,
    QUERY_PARAM_TAB_USERS_AND_PERMISSIONS,
    SETTINGS_URL,
    SUPERUSER,
    TERMS_AND_CONDITIONS_URL,
    COMPANY_LOOKUP_URL,
};
