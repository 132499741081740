import {Palette} from 'types/theme';

const _brandColors = Object.freeze({
    revBlack: '#121819',
    revBrandGreen: '#008542',
    revBlue: '#00659E',
    revCharcoalGrey: '#1a2520',
    revDarkGreen: '#1c362f',
    revDarkGunmetal: '#2d3640',
    revGray: '#aaadb2',
    revGunmetalGray: '#4b5968',
    revLightGray: '#f0f0f0',
    revLightGreen: '#e0eae3',
    revNavy: '#00327D',
    revMidGreen: '#054835',
    revOrange: '#EC7100',
    revRed: '#d62839',
    revWhite: '#ffffff',
});

const _a11yColors = Object.freeze({
    minContrastText: '#767676',
});

const _partialSelectionColor = '#FFA500';

const palette: Partial<Palette> = Object.freeze({
    action: {
        active: _brandColors.revBrandGreen,
        hover: _brandColors.revLightGreen,
        selected: _brandColors.revDarkGreen,
        disabled: _brandColors.revGray,
        partiallySelected: _partialSelectionColor,
    },
    background: {
        default: _brandColors.revLightGray,
        content: _brandColors.revWhite,
        selected: _brandColors.revLightGreen,
    },
    primary: {
        light: _brandColors.revWhite,
        main: _brandColors.revBrandGreen,
        dark: _brandColors.revMidGreen,
        disabled: _brandColors.revGray,
        danger: _brandColors.revRed,
        black: _brandColors.revBlack,
    },
    secondary: {
        light: _brandColors.revGray,
        main: _brandColors.revGunmetalGray,
        dark: _brandColors.revDarkGunmetal,
    },
    error: {
        main: _brandColors.revRed,
    },
    success: {
        main: _brandColors.revBrandGreen,
    },
    text: {
        default: _brandColors.revDarkGunmetal,
        interactive: _brandColors.revGunmetalGray,
        selected: _brandColors.revWhite,
        minContrast: _a11yColors.minContrastText,
        contrast: _brandColors.revWhite,
        primary: _brandColors.revBlack,
        secondary: _brandColors.revGunmetalGray,
        focus: _brandColors.revBlack,
        linkHover: _brandColors.revNavy,
    },
    bullseye: {
        lookalikeCompanies: _brandColors.revBrandGreen,
        targetListCompanies: [
            _brandColors.revRed,
            _brandColors.revBlue,
            _brandColors.revOrange,
        ],
        seedCompanies: _brandColors.revCharcoalGrey,
        sampleCompanies: _brandColors.revGray,
    },
    aim: {
        chart: ['#4b5968', '#76a5af', '#8bc5b4', '#d9ead3'],
    },
    signalTypeBackground: {
        COMPANY_SUMMARY: '#F5F8FC',
        EMPLOYEE: '#F9F5FC',
        FIRMOPGRAPHIC: '#FFF9F5',
        JOB_POSTING: '#F5FCF8',
        MIXED: '#FCFBF5',
    },
    signalTypeBorder: {
        COMPANY_SUMMARY: '#4A7AB7',
        EMPLOYEE: '#8E44AD',
        FIRMOPGRAPHIC: '#F19B4D',
        JOB_POSTING: '#27AE60',
        MIXED: '#E74C3C',
    },
    visualization: [
        '#00327D',
        '#00659E',
        '#EC7100',
        _brandColors.revBrandGreen,
        _brandColors.revMidGreen,
        _brandColors.revGunmetalGray,
        _brandColors.revBlack,
        _brandColors.revBlack,
    ],
});

export default palette;
