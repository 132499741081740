const localStorageUtil = {
    set: function (key: string, value?: unknown) {
        if (typeof value === 'undefined') {
            return;
        }

        const stringifiedValue = JSON.stringify(value);
        localStorage.setItem(key, stringifiedValue);
    },
    get: function (key: string): unknown {
        const value = localStorage.getItem(key);

        if (typeof value === 'undefined') {
            return;
        }

        try {
            return JSON.parse(value) as unknown;
        } catch (e) {
            return;
        }
    },
    remove: function (key: string) {
        localStorage.removeItem(key);
    },
};

export {localStorageUtil};
