/* eslint-disable @typescript-eslint/no-restricted-imports */
import {
    ApolloCache,
    ApolloError,
    DefaultContext,
    DocumentNode,
    MutationFunctionOptions,
    MutationHookOptions,
    MutationTuple,
    OperationVariables,
    QueryHookOptions,
    QueryResult,
    SingleExecutionResult,
    useMutation as apolloUseMutation,
    useQuery as apolloUseQuery,
    useLazyQuery,
} from '@apollo/client';
import {LOGIN_URL} from 'components/util/routeConstants';
import Router from 'next/router';
import {signOut} from 'next-auth/client';
import {logger} from 'utils/logger';

const UNAUTHENTICATED_USER_ERROR_MESSAGE = 'Unauthenticated User';

const getOperationResponseErrors = (data: unknown): ApolloError => {
    const operationNames = Object.keys(data ?? {});
    const errors = [];
    for (const name of operationNames) {
        if (data?.[name]?.status?.error) {
            const errorMessage: string = data[name].status.error.message;
            errors.push({
                message: `operation: ${name} ${errorMessage}`,
            });
        }
    }
    if (errors.length > 0) {
        return new ApolloError({graphQLErrors: errors});
    }
};

export const ejectRemovedUser = async () => {
    await signOut();
    await Router.push(LOGIN_URL);
};

const wrapOnCompletedToHandleResponseErrors = (options: QueryHookOptions) => {
    if (!options.onCompleted && !options.onError) {
        return;
    }

    const originalOnCompleted = options.onCompleted;
    options.onCompleted = (data) => {
        const responseError = getOperationResponseErrors(data);
        if (responseError) {
            if (
                responseError.message.includes(
                    UNAUTHENTICATED_USER_ERROR_MESSAGE
                )
            ) {
                ejectRemovedUser().catch((e) => logger.error(e));
            }

            if (options.onError) {
                options.onError(responseError);
            }
        } else if (originalOnCompleted) {
            originalOnCompleted(data);
        }
    };
};

export type mutationFunction<TData, TVariables> = (
    options?: MutationFunctionOptions<
        TData,
        TVariables,
        DefaultContext,
        ApolloCache<unknown>
    >
) => Promise<
    SingleExecutionResult<
        TData,
        Record<string, unknown>,
        Record<string, unknown>
    >
>;

const handleAuthenticationErrors = (data) => {
    if (
        getOperationResponseErrors(data)?.message.includes(
            UNAUTHENTICATED_USER_ERROR_MESSAGE
        )
    ) {
        ejectRemovedUser().catch((e) => logger.error(e));
    }
};

const useMutation = <TData, TVariables>(
    mutation: DocumentNode,
    options: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
    const optionsClone = {...options};

    wrapOnCompletedToHandleResponseErrors(optionsClone);

    const [runMutation, mutationOptions] = apolloUseMutation<TData, TVariables>(
        mutation,
        optionsClone
    );
    const {
        data,
        error: mutationError,
        ...resultOptions
    } = mutationOptions || {
        data: null,
        error: null,
        loading: false,
        called: false,
        client: null,
        reset: null,
    };
    handleAuthenticationErrors(data);
    return [
        runMutation,
        {
            data,
            error: mutationError || getOperationResponseErrors(data),
            ...resultOptions,
        },
    ];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useQuery = <TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
    const optionsClone = {...options};

    wrapOnCompletedToHandleResponseErrors(optionsClone);

    const {
        data,
        error: queryError,
        ...resultOptions
    } = apolloUseQuery<TData, TVariables>(query, optionsClone);

    handleAuthenticationErrors(data);

    return {
        data,
        error: queryError || getOperationResponseErrors(data),
        ...resultOptions,
    };
};

export {useMutation, useQuery, useLazyQuery};
