import {ApolloLink} from '@apollo/client';

/**
 * Should match all of the following:
 *   '2023-09-05T15:39:46'
 *   '2023-09-05 15:39:46'
 *   '2023-09-05T15:39:46.048611'
 *   '2023-09-05 15:39:46.048611'
 *   '2023-09-05T15:39:46.048611+0700'
 *   '2023-09-05 15:39:46.048611+0700'
 */
const isoDateRegex =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}[T ][0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([+-][0-9]{4})?$/;
const isoDateWithoutTimezoneRegex =
    /^[0-9]{4}-[0-9]{2}-[0-9]{2}[T ][0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?$/;

export const dateTimeLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        transformDateTimeFields(response.data);
        return response;
    });
});

export function transformDateTimeFields(data: object[] | object | null): void {
    if (Array.isArray(data)) {
        data.forEach((value) => transformDateTimeFields(value));
    } else if (typeof data === 'object' && data !== null) {
        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (
                typeof value === 'string' &&
                looksLikeADatetimeField(key, value)
            ) {
                data[key] = tryToParseDateString(value);
            } else if (typeof value === 'object') {
                transformDateTimeFields(value);
            }
        });
    }
}

function looksLikeADatetimeField(key: string, value: string): boolean {
    return (
        (key.endsWith('Date') || key.endsWith('At')) &&
        value.match(isoDateRegex) !== null
    );
}

function tryToParseDateString(dateString: string): Date | string {
    if (isIsoFormatWithoutTimezone(dateString)) {
        return new Date(`${dateString}+0000`);
    }

    const date = new Date(dateString);

    if (!isValidDate(date)) {
        return dateString;
    }

    return date;
}

function isIsoFormatWithoutTimezone(dateString: string): boolean {
    return dateString.match(isoDateWithoutTimezoneRegex) !== null;
}

function isValidDate(date: Date): boolean {
    return !isNaN(+date);
}
