import {createStore} from 'zustand/vanilla';
import {persist, createJSONStorage} from 'zustand/middleware';

export const LAST_KNOWN_ROUTE_KEY = 'zustandLastKnownRoute';

export type LastKnownRouteState = {
    route: string;
    ignoreRoute?: boolean;
};

export type LastKnownRouteActions = {
    setCurrentRoute: (path?: string, override?: boolean) => void;
    clearCurrentRoute: () => void;
};

export type LastKnownStore = LastKnownRouteState & LastKnownRouteActions;

export const initLastKnownStore = (): LastKnownRouteState => {
    return {route: '', ignoreRoute: false};
};

export const defaultInitState: LastKnownRouteState = {
    route: '',
};

export const createLastKnownRouteStore = (
    initState: LastKnownRouteState = defaultInitState
) => {
    return createStore<LastKnownRouteState & LastKnownRouteActions>()(
        persist(
            (set) => ({
                ...initState,
                setCurrentRoute: (path, override = false) =>
                    set(() => ({route: path, ignoreRoute: override})),
                clearCurrentRoute: () =>
                    set(() => ({route: '', ignoreRoute: false})),
            }),
            {
                name: LAST_KNOWN_ROUTE_KEY,
                storage: createJSONStorage(() => localStorage),
            }
        )
    );
};
